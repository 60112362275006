<template>
  <div class="container-max-width">
    <header-page-derek class="w-100" :title="'Franquicias'" :skeleton="false" />
    <div class="container-franquicias">
      <div class="img-franquicias col-12 col-lg-5">
        <img src="@/assets/img/img_franquicia.jpg" alt="imagen franquicias" />
      </div>
      <div class="formulario-franquicia col-12 col-lg-6">
        <div class="titulo-franquicia">
          <h1>Únete al exclusivo mundo derek con tu propia tienda</h1>
        </div>
        <p class="mb-4">
          ¿Te gusta la moda o eres comerciante? La franquicia derek es una
          excelente oportunidad de negocio para ti. Maximiza tus ingresos con un
          modelo de negocio probado por más de 20 años con alto margen
          comercial. Déjanos tus datos para ponernos en contacto contigo.
        </p>
        <div class="content-formulario">
          <div class="payment-input-left w-100 me-0">
            <input
              v-model="correo"
              autocomplete="email"
              class="payment-input w-100"
              type="email"
              placeholder="Correo"
              @blur="loseFocus('email')"
              @focus="campoCorreoRequerido = false"
            />
            <span v-if="campoCorreoRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="correo" class="newlatter-error-input">Correo</span>
          </div>
          <div class="payment-input-left">
            <input
              v-model="nombre"
              autocomplete="given-name"
              class="payment-input w-100"
              type="given-name"
              placeholder="Nombres"
              @blur="loseFocus('nom')"
              @focus="campoNombreRequerido = false"
            />
            <span v-if="campoNombreRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="nombre" class="newlatter-error-input">Nombres</span>
          </div>
          <div class="payment-input-right">
            <input
              v-model="tel"
              class="payment-input w-100"
              type="number"
              placeholder="Telefono de contacto"
              @blur="loseFocus('tel')"
              @focus="campoTelRequerido = false"
            />
            <span v-if="campoTelRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="tel" class="newlatter-error-input"
              >Telefono de contacto</span
            >
          </div>
          <div class="payment-input-left">
            <input
              v-model="pais"
              class="payment-input w-100"
              placeholder="País"
              @blur="loseFocus('pais')"
              @focus="campoPaisRequerido = false"
            />
            <span v-if="campoPaisRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="cedula" class="newlatter-error-input">País</span>
          </div>
          <div class="payment-input-right">
            <input
              v-model="ciudad"
              class="payment-input w-100"
              placeholder="Ciudad"
              @blur="loseFocus('ciudad')"
              @focus="campoCiudadRequerido = false"
            />
            <span v-if="campoCiudadRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="cedula" class="newlatter-error-input">Ciudad</span>
          </div>
          <div class="payment-input-left">
            <div
              class="d-flex align-items-center justify-content-center px-2"
              style="background-color: #F5F5F5;"
            >
              <label class="d-flex my-4 mx-0 ms-md-1">
                <input
                  v-model="tyc"
                  class="tyc-checkbox"
                  type="checkbox"
                  checkmark="checkmark"
                />
                <span class="checkmark"></span>
              </label>
              <p class="mb-0 d-inline">
                Acepto
                <router-link
                  style="font-size: 1rem; color: #62285e; width: 100%;"
                  target="_blank"
                  :to="{
                    name: 'Politicas',
                    params: { slug: 'politica-de-privacidad' },
                  }"
                  >política tratamiento datos personales.</router-link
                >
              </p>
            </div>
            <span v-if="campoTycRequerido" class="payment-error-label"
              >Acepta los terminos y condiciones</span
            >
          </div>
          <div class="payment-input-right">
            <button class="btn-primary w-100" @click="sendFormulario()">
              Enviar formulario
            </button>
          </div>
          <p v-if="msgErrorSolicitud" class="msgError fs-6 mx-auto mt-2">
            {{ msgErrorSolicitud }}
          </p>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-done-franquicia"
      body-bg-variant="rgba(0, 13, 80, .5)"
      centered
      hide-footer
      hide-header
    >
      <div class="body-modal-newslatter-done">
        <img
          class="close-icon-modal"
          src="@/assets/icons/delete-gray-icon.svg"
          alt="boton cerrar"
        />
        <div
          class="d-flex flex-wrap justify-content-center text-center position-relative"
        >
          <div class="divider-modal-newlatter">
            <div class="circle-newslatter" />
          </div>
          <img
            class="mb-3"
            style="z-index: 2"
            src="@/assets/icons/diamont-icon.svg"
            alt="decorador-diamont"
          />
          <div class="box-newslatter">
            <p class="bold">¡Solicitud registrada!</p>
            <p class="fs-6">
              Pronto nos pondremos en contacto contigo para brindarte más
              información.
            </p>
            <button
              class="btn-primary"
              @click="$bvModal.hide('modal-done-franquicia')"
            >
              ¡Entendido!
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'
export default {
  components: { HeaderPageDerek },
  data() {
    return {
      correo: '',
      campoCorreoRequerido: false,
      nombre: '',
      campoNombreRequerido: false,
      tel: '',
      campoTelRequerido: false,
      ciudad: '',
      campoCiudadRequerido: false,
      pais: '',
      campoPaisRequerido: false,
      telefono: '',
      campoTelefonoRequerido: false,
      tyc: false,
      campoTycRequerido: false,
      msgErrorSolicitud: '',
    }
  },
  computed: {
    /* eslint-disable */
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validateNom() {
      return this.nombre.length > 2
    },
    validateTel() {
      return this.tel.length > 6 && this.tel.length < 11
    },
    validatePais() {
      return this.pais.length > 2
    },
    validateCiudad() {
      return this.ciudad.length > 2
    },
  },
  watch: {
    tyc() {
      if (this.tyc) {
        this.campoTycRequerido = false
      }
    },
  },
  methods: {
    loseFocus(input) {
      if (input == 'email') {
        this.campoCorreoRequerido = !this.validateEmail
      }
      if (input == 'nom') {
        this.campoNombreRequerido = !this.validateNom
      }
      if (input == 'tel') {
        this.campoTelRequerido = !this.validateTel
      }
      if (input == 'pais') {
        this.campoPaisRequerido = !this.validatePais
      }
      if (input == 'ciudad') {
        this.campoCiudadRequerido = !this.validateCiudad
      }
    },
    async sendFormulario() {
      this.msgErrorSolicitud = ''
      if (
        this.validateEmail &&
        this.validateNom &&
        this.validateTel &&
        this.validateCiudad &&
        this.validatePais &&
        this.tyc
      ) {
        this.$recaptchaLoaded().then(async () => {
          this.$recaptcha('login').then(async token => {
            if (token) {
              this.$store.state.loaderDerek = true
              let data = new FormData()
              data.append('correo', this.correo)
              data.append('nombres', this.nombre)
              data.append('pais', this.pais)
              data.append('ciudad', this.ciudad)
              data.append('telefono', this.tel)
              await this.axios({
                method: 'POST',
                headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                },
                url: '/franchise/form/register',
                data: data,
              })
                .then(() => {
                  this.$bvModal.show('modal-done-franquicia')
                  this.correo = ''
                  this.campoCorreoRequerido = false
                  this.nombre = ''
                  this.campoNombreRequerido = false
                  this.tel = ''
                  this.campoTelRequerido = false
                  this.ciudad = null
                  this.campoCiudadRequerido = false
                  this.cedula = ''
                  this.campoCedulaRequerido = false
                  this.telefono = ''
                  this.campoTelefonoRequerido = false
                  this.tyc = false
                  this.campoTycRequerido = false
                  this.msgErrorSolicitud = ''
                  this.$store.state.loaderDerek = false
                })
                .catch(err => {
                  console.log(error)
                  if (err.response.status == 500) {
                    this.msgErrorSolicitud =
                      'Parece que algo salió mal, intentalo más tarde'
                  } else {
                    let message = ''
                    for (const property in err.response.data.errors) {
                      message =
                        message + err.response.data.errors[property][0] + ' '
                    }
                    this.msgErrorSolicitud = message
                  }
                  this.$store.state.loaderDerek = false
                })
            }
          })
        })
      } else {
        this.campoCorreoRequerido = !this.validateEmail
        this.campoNombreRequerido = !this.validateNom
        this.campoTelRequerido = !this.validateTel
        this.campoCiudadRequerido = !this.ciudad
        this.campoCedulaRequerido = !this.validateCed
        this.campoTycRequerido = !this.tyc
      }
    },
  },
}
</script>
<style>
.container-franquicias {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}
.img-franquicias {
  margin: auto;
  max-width: 424px;
}
.formulario-franquicia {
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  position: relative;
}
.titulo-franquicia {
  position: relative;
  width: 120%;
  left: -20%;
  background-color: #fbeee3;
  padding: 1rem 3rem;
  margin-bottom: 2rem;
}
.titulo-franquicia h1 {
  font-size: 30px;
}
.content-formulario {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .img-franquicias {
    max-height: 350px;
    overflow: hidden;
  }
  .titulo-franquicia {
    width: 100%;
    left: 0;
    top: -3rem;
    margin-bottom: 0;
  }
  .formulario-franquicia {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .container-franquicias {
    width: 100%;
  }
  .formulario-franquicia {
    width: 90%;
  }
  .img-franquicias {
    max-height: 350px;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
  }
  .img-franquicias img {
    width: 100%;
  }
  .titulo-franquicia {
    width: 105.6%;
    padding: 1rem;
  }
}
</style>
